var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forums__categories--container"},[(_vm.isLoadingFlag)?_c('div',{staticClass:"forums__categories--loader"},[_c('Loader')],1):_c('div',{staticClass:"accordion forums-accordion",attrs:{"role":"tablist"}},[_c('div',{staticClass:"forums__all--posts",class:{ active: !_vm.getTopicId },on:{"click":_vm.setAllPostsView}},[_c('img',{attrs:{"src":!_vm.getTopicId
            ? require(`@/assets/images/vle/forums/all-post.svg`)
            : require(`@/assets/images/vle/forums/all-post-black.svg`),"alt":"all posts"}}),_c('span',[_vm._v(_vm._s(_vm.$t("discussions.post.all_posts")))])]),_vm._l((_vm.showDiscussionTopics),function(item,index){return _c('b-card',{key:item.name,class:{
        active: item.id === _vm.getTopicId,
        'no__sub--category': item.id !== null
      },attrs:{"no-body":""}},[_c('b-card-header',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`accordion-${index}`),expression:"`accordion-${index}`"}],staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"},on:{"click":function($event){return _vm.handleMainCategoryClick(item)}}},[_c('img',{attrs:{"src":item.id === _vm.getTopicId
              ? require(`@/assets/images/vle/forums/category-white.svg`)
              : require(`@/assets/images/vle/forums/category.svg`),"alt":"category"}}),_c('b-button',{attrs:{"title":item.name}},[_vm._v(_vm._s(item.name))]),(item?.categories.length > 0)?_c('img',{staticClass:"arrow",attrs:{"src":require(`@/assets/images/vle/chevron-black.svg`),"alt":"arrow"}}):_c('div',{staticClass:"post-count"},[_vm._v(" "+_vm._s(item.post_count)+" "),(item?.unread_count > 0)?_c('span',{staticClass:"unread-dot"}):_vm._e()])],1),_c('b-collapse',{staticClass:"card__body--container",attrs:{"id":`accordion-${index}`,"accordion":"my-accordion","role":"tabpanel","visible":_vm.isAnySubCategoryActive(item)}},_vm._l((item.categories),function(smallList,sindex){return _c('b-card-body',{key:sindex,class:{
            active: smallList.id === _vm.getTopicId
          },on:{"click":function($event){$event.stopPropagation();return _vm.handleSubCategoryClick(item, smallList, index)}}},[_c('b-card-text',{attrs:{"title":smallList.name}},[_vm._v(_vm._s(smallList.name))]),_c('div',{staticClass:"post-count"},[_vm._v(" "+_vm._s(smallList.post_count)+" "),(smallList?.unread_count > 0)?_c('span',{staticClass:"unread-dot"}):_vm._e()])],1)}),1)],1)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }