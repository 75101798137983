import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getSelectedCourse"]),
    allSequences() {
      let arr = [];
      this.getSelectedCourse.sections.forEach(m => {
        m.sequences.forEach(s => {
          arr.push(s);
        });
      });
      return arr;
    }
  },
  methods: {
    goToPage(params, name, query) {
      this.$router.push({
        name: name,
        params: params,
        query: query
      });
    },
    getUnitIcon(type, isActive) {
      switch (type?.toUpperCase()) {
        case "HTML":
          if (isActive) {
            return require("@/assets/images/module-list/reading-active.svg");
          } else {
            return require("@/assets/images/module-list/reading.svg");
          }
        case "PROBLEM":
        case "LIBRARY_CONTENT":
          if (isActive) {
            return require("@/assets/images/module-list/problem-active.svg");
          } else {
            return require("@/assets/images/module-list/problem.svg");
          }
        case "VIDEO":
          if (isActive) {
            return require("@/assets/images/module-list/video-active.svg");
          } else {
            return require("@/assets/images/module-list/video.svg");
          }
        case "DISCUSSION":
          if (isActive) {
            return require("@/assets/images/module-list/discussion-active.svg");
          } else {
            return require("@/assets/images/module-list/discussion.svg");
          }
        case "ZOOM":
        case "LTI_CONSUMER":
        case "LTI":
          if (isActive) {
            return require("@/assets/images/module-list/zoom-active.svg");
          } else {
            return require("@/assets/images/module-list/zoom.svg");
          }
        case "SCORM_V2":
        case "CMI5":
          if (isActive) {
            return require("@/assets/images/module-list/interactive-active.svg");
          } else {
            return require("@/assets/images/module-list/interactive.svg");
          }
        case "NON_MANDATORY_SURVEY":
        case "NON_MANDATORY_POLL":
          if (isActive) {
            return require("@/assets/images/module-list/survey.svg");
          } else {
            return require("@/assets/images/module-list/survey.svg");
          }
        default:
          return require("@/assets/images/module-list/reading.svg");
      }
    },
    getUnitType(type) {
      switch (type.toUpperCase()) {
        case "HTML":
          return this.$i18n.t("course.modules.reading");
        case "PROBLEM":
        case "LIBRARY_CONTENT":
          return this.$i18n.t("course.modules.assessment");
        case "VIDEO":
          return this.$i18n.t("course.modules.video");
        case "DISCUSSION":
          return this.$i18n.t("course.modules.discussion");
        case "ZOOM":
        case "LTI_CONSUMER":
        case "LTI":
          return "Zoom";
        case "SCORM_V2":
          return this.$i18n.t("course.modules.interactive");
        case "CMI5":
          return this.$i18n.t("course.modules.interactive");
        case "NON_MANDATORY_SURVEY":
        case "NON_MANDATORY_POLL":
          return this.$i18n.t("course.modules.survey");
        default:
          return this.$i18n.t("course.modules.reading");
      }
    },
    goToCouresData() {
      let routeData = this.$router.resolve({
        name: "Courses Detail",
        params: { id: this.$route.params.id }
      });
      window.open(routeData.href, "_blank");
    },
    navigateToPrerequisite(preReq) {
      let sequence = this.allSequences.find(s => s.id === preReq.block_id),
        hasResumeBlock = sequence.units.find(u => !u.complete),
        unitId;
      hasResumeBlock
        ? (unitId = hasResumeBlock.id)
        : (unitId = sequence.units[0].id);
      this.selectModule(sequence.id, unitId);
    }
  }
};
